import './VejaMaisSDContainer.css'
import SD0Caracteristicas from '../components/SD0Caracteristicas'
import SD0RealizarPedido from '../components/SD0RealizarPedido'
import AreaTecnica from '../components/AreaTecnica'
function VejaMaisSDContainer() {

    return <>
      <div className='compreAquiContainer'>
        <span className='compreAquiFaixa'>.</span>
        <div className="compreAqui">Compre Aqui</div>
      </div>
      <div className='compreAquiSquareContainer'> 
        <div className='compreAquiSquare'>
     
          <img src='plate.png' alt='plate' className='plateVejaMais' ></img>
          <SD0Caracteristicas></SD0Caracteristicas>
          <SD0RealizarPedido></SD0RealizarPedido>

        </div>
      </div>

      <div style={{marginTop: '20px', flexDirection: 'column'}} className='full-width full-centered'>
        <img alt='info' src='/SDINFORECTANGLE.png' height={200}></img>
        <div className="compreAqui">Área Técnica</div>
      </div>

      <AreaTecnica></AreaTecnica>

    </>
}

export default VejaMaisSDContainer