import React, { useState, useEffect, useCallback } from "react";
import sparttanisAxios from "../../global/SparttanisAxios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  DialogContentText,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Delete from "@mui/icons-material/Delete";
import { isMobile } from "react-device-detect";
import ContadorPedidos from './componentes/ContadorPedidos';
import "./CarrinhoDialog.css";

const CarrinhoDialog = ({
  setAbrirModal,
  abrirModal,
  chamarNotificacaoInfo,
  chamarNotificacaoSucesso,
  chamarNotificacaoErro
}) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(abrirModal);
  const [openConfirmarCompra, setOpenConfirmarCompra] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [carrinho, setCarrinho] = useState();

  useEffect(() => {
    const obterPedidosDoCarrinho = async () => {
      try {
        const response = await sparttanisAxios.get(`/carrinho/${localStorage.getItem("ntt")}`);
        const dados = response.data;
        setPedidos(dados?.Pedido || []);
        setCarrinho(dados);
      } catch (error) {
        console.error("Erro ao obter pedidos:", error);
        chamarNotificacaoErro('Não foi possível obter os pedidos do carrinho.');
      }
    };

    obterPedidosDoCarrinho();

    const handleRecarregarCarrinho = () => obterPedidosDoCarrinho();

    document.addEventListener('recarregarCarrinho', handleRecarregarCarrinho);

    return () => {
      document.removeEventListener('recarregarCarrinho', handleRecarregarCarrinho);
    };
  }, [chamarNotificacaoErro]);

  useEffect(() => {
    setOpen(abrirModal);
  }, [abrirModal]);

  const formatarPreco = (numero) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numero);
  };

  const removerDoCarrinho = async (registro) => {
    try {
      setLoading(true);
      await sparttanisAxios.post(`/pedido-remove/${registro.id}/${carrinho.id}`);
      setPedidos((pedidos) => pedidos.filter((pedido) => pedido.id !== registro.id));
      chamarNotificacaoSucesso('Produto removido do carrinho.');
    } catch (error) {
      console.error('Erro ao remover o produto:', error);
      chamarNotificacaoErro('Não foi possível remover o produto do carrinho.');
    } finally {
      setLoading(false);
    }
  };

  const confirmarCompra = async () => {
    try {
      await sparttanisAxios.post(`/confirmar-compra/${carrinho.id}`);
      setCarrinho(null);
      setPedidos([]);
      const eventoPersonalizado = new CustomEvent('sucesso', {
        detail: { mensagem: 'Compra Confirmada com Sucesso. Entraremos em Contato com Você!' }
      });
      document.dispatchEvent(eventoPersonalizado) 
      const eventoPersonalizado2 = new CustomEvent('recarregarCarrinho', {
        detail: { mensagem: ' ' }
      });
      document.dispatchEvent(eventoPersonalizado2) 
      handleClose();
    } catch (error) {
      console.error('Erro ao confirmar compra:', error);
      const eventoPersonalizado = new CustomEvent('erro', {
        detail: { mensagem: 'Erro ao Confirmar Compra. Tente Novamente.' }
      });
      document.dispatchEvent(eventoPersonalizado) 
    }
  };

  const handleClose = () => {
    setOpenConfirmarCompra(false);
    setOpen(false);
    setAbrirModal(false);
  };

  const handlePrecoTotal = () => {
    return formatarPreco(pedidos.reduce((total, pedido) => total + (pedido.quantidade * (pedido.Produto?.preco || 0)), 0));
  };

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle sx={{ m: 0, p: 2, background: "#282828", color: "white" }}>
        Carrinho <ShoppingCartIcon />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ background: "#242220" }} dividers>
        <Dialog
          open={openConfirmarCompra}
          keepMounted
          onClose={() => setOpenConfirmarCompra(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>Confirmar Compra</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <p>Valor Total: {handlePrecoTotal()}</p>
              <p>O endereço de entrega é o que consta em seu cadastro. Você pode alterá-lo em seu perfil.</p>
              <p>Você deseja confirmar a compra?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button type="button" onClick={handleClose}>Cancelar</Button>
            <Button type="button" onClick={confirmarCompra}>Confirmar</Button>
          </DialogActions>
        </Dialog>

        {isMobile && (<>
          <div className="fullCentered">
            <Button variant="contained" color="secondary" onClick={() => setOpenConfirmarCompra(true)}>
              Confirmar Compra <SaveIcon sx={{ marginLeft: "10px" }} />
            </Button>
          </div>
          <hr/>
        </>)}
       
        <div style={{ display: "flex", color: "white" }}>
       
          {!loading ? (
            <table className="tabelaCarrinho" style={{ width: isMobile ? "300px" : "1200px" }}>
              <thead>
                <tr>
                  <th>Produto</th>
                  <th>Quantidade</th>
                  <th>Preço</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {pedidos.map((pedido) => (
                  <tr key={pedido.id}>
                    <td>{pedido.Produto?.nome}</td>
                    <td><ContadorPedidos registro={pedido} /></td>
                    <td>{formatarPreco(pedido.Produto?.preco * pedido.quantidade)}</td>
                    <td>
                      <Delete onClick={() => removerDoCarrinho(pedido)} className="botaoExcluir" sx={{ fontSize: "30px" }} />
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={4} style={{ textAlign: 'center' }}>
                    <span className="valorTotal">Valor Total: {handlePrecoTotal()}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <span><h2>Carregando...</h2></span>
          )}
        </div>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#282828", color: "white", justifyContent: "center" }}>
        <Button variant="contained" color="secondary" onClick={() => setOpenConfirmarCompra(true)}>
          Confirmar Compra <SaveIcon sx={{ marginLeft: "10px" }} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CarrinhoDialog;
