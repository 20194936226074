import axios from 'axios';

// Obtém o token de autenticação do localStorage
const token = localStorage.getItem('token');

// Cria uma instância do Axios
const sparttanisAxios = axios.create({
  baseURL: /* process.env.REACT_APP_MODE === 'dev' ? 'http://localhost:1881/api/rest'  : */'https://noderedapis.sparttanis.com/', // substitua pela URL da sua API
  headers: {
    Authorization: `Bearer ${token}`
  }
});

export default sparttanisAxios;
