import sparttanisAxios from "../../global/SparttanisAxios"
import React from "react" 
import ProductComponent from "./components/ProductComponent"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faGear, faKeyboard,faArrowUpWideShort, faArrowDownWideShort, faList, faDollarSign, faBan, faTrash, faRefresh, faSort } from '@fortawesome/free-solid-svg-icons';
import './Orders.css'
 
 
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import  {NumericFormat} from 'react-number-format';
import Pagination from '@mui/material/Pagination';
import { CircularProgress } from "@mui/material";
import { isMobile } from 'react-device-detect';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

function Orders() {
    const [openAddCarrinho, setOpenAddCarrinho] = React.useState(false);
    const [produtoCarrinho, setProdutoCarrinho] = React.useState(null)
    const categoriasFiltro = ['Nome', 'Preço', 'Tipo']
    const [order, setOrder] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [desc, setDesc] = React.useState(true)
    const [preco, setPreco] = React.useState(0)
    const [produtos, setProdutos] = React.useState([])
    const [categorias, setCategorias] = React.useState([])
    const [precoMin, setPrecoMin] = React.useState(0)
    const [precoMax, setPrecoMax] = React.useState(isMobile ? 15000 : 15000)
    const [step, setStep] = React.useState(5)
    const [totalCount, setTotalCount] = React.useState(0)
    const [pagina, setPagina] = React.useState(1)
    const [filtro, setFiltro] = React.useState({
      pagina,
      preco
    })
    const [tipoId, setTipoId] = React.useState(null)
    const [nome, setNome] = React.useState('')

    async function obterProdutos(clear, resetPagina) {
        setLoading(true)
        try {
            const obj = !clear ? {
              pagina: resetPagina ? 1 : pagina,
              preco,
              nome,
              tipoId,
              desc,
              order
            } : {pagina: resetPagina ? 1 : pagina }
            setProdutos([])
            const apiProdutos = await sparttanisAxios.post('produtos', obj)
            if (clear) {
              setOrder('')
              setPreco(0)
              setNome('')
              setTipoId(null)
            }
            if (resetPagina) {
              setPagina(1)
            }
    
            setProdutos(apiProdutos.data.data)

            if (apiProdutos.data.totalCount) {
              const soma = apiProdutos.data.totalCount % 20 !== 0
              let total = parseInt((apiProdutos.data.totalCount / 20))
              total = soma ? total + 1 : total
              setTotalCount(total)
            } 

          setLoading(false)
        } catch (e) {
          setLoading(false)
        }
      
    }

    async function obterCategoriasProdutos() {
        try {
            const apiCategorias = await sparttanisAxios.get('todas-categorias', filtro)
            setCategorias(apiCategorias.data.data.tipoProdutos) 
        } catch (e) {
          console.log(e)
        }
      
    }

    React.useEffect(() => { 
    }, [produtos])

    React.useEffect(() => {
      if (produtoCarrinho?.id) {
        setOpenAddCarrinho(true)
      } else {
        setOpenAddCarrinho(false)
      }
    }, [produtoCarrinho])

    React.useEffect(() =>
        {  
            obterProdutos(true)
            obterCategoriasProdutos()
        }
    , [])

    React.useEffect(() =>
      { 
        obterProdutos()
        obterCategoriasProdutos()
      }
  , [pagina, desc])

    const handleOrder = (value) => {
        setDesc(value)
    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const handleCloseCarrinho = () => {
      setProdutoCarrinho(false);
      setOpenAddCarrinho(false);
    };

    function valuetext() {
        return `R$ ${preco}`;
      }

    function handleRange(value, max, price) {
      
      let parsed = null

      try {
        if (value.split(',').length > 0) {
          value = value.replace(',', '')
        }

        parsed = parseFloat(value.split('R$ ')[1]) 
      } catch (e) {
        console.log(e)
        parsed = 0.0
      }
      console.log(parsed)

      if (Number.isNaN(parsed)) {
        setPrecoMax(15000)
        setPrecoMin(0)
        return
      }
      const p = parsed



      if (price) {

        if (p > precoMax) {
          setPrecoMax(p)
        } 
        setPreco(p)
        return  
      }

      if (max) {

        if (p < precoMin) {
          setPrecoMin(0)
        }
        
        setPrecoMax(p)
        setPreco(p)
        return
      }

      setPrecoMin(p)
    }

    React.useEffect(() => {
 
      if (precoMax >= 5000) {
        setStep(200)
      } else if (precoMax >= 1000) {
        setStep(50)
      } else {
        setStep(5)
      }
    } , [precoMax, precoMin])

    const adicionarAoCarrinho = async (registro) => {
      try {
          await sparttanisAxios.post('/pedido-add', {
              idKeycloak: localStorage.getItem('ntt'),
              pedidoInput: {
                  produtoId: registro.id,
                  quantidade: 1,
              }
          })
          const eventoPersonalizado = new CustomEvent('recarregarCarrinho', {
            detail: { mensagem: 'recarregar' }
          });
          document.dispatchEvent(eventoPersonalizado)

          const eventoPersonalizadoCarrinho = new CustomEvent('sucesso', {
            detail: { mensagem: 'Carrinho Atualizado!' }
          });
          document.dispatchEvent(eventoPersonalizadoCarrinho)
          setOpenAddCarrinho(false)
          setProdutoCarrinho(false)
      } catch(e) {}
  }

    return (<>

      <Dialog  
      fullScreen={isMobile}
        maxWidth={900}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle sx={{ m: 0, p: 2, background: '#282828', color: 'white' }} id="customized-dialog-title">
          Opções de Busca
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{background: '#F9EDE0'}} dividers>
            <Typography sx={{fontSize: !isMobile ? '1.25rem' : '1rem'}} gutterBottom>
                <FontAwesomeIcon icon={faDollarSign}></FontAwesomeIcon>        { 'Valor até - '}    
                
              <NumericFormat
                thousandSeparator={true}
                decimalScale={2}
                onChange={(e) => handleRange(e.target.value, false, true)}
                fixedDecimalScale={true}
                prefix={'R$ '}
                placeholder="Digite o valor"
                allowNegative={false}
                value={preco} 
                style={{width: '120px', marginBottom: '15px'}}
                isNumericString={true}
              /> 
                <FontAwesomeIcon style={{marginLeft: '10px'}} icon={faKeyboard}></FontAwesomeIcon>
            </Typography>
          
            <Typography gutterBottom>

           
            <div className="container-preco-range">
            {!isMobile && (<>
              <label>Min</label>
              <NumericFormat
                thousandSeparator={true}
                decimalScale={2}
                onChange={(e) => handleRange(e.target.value, false)}
                fixedDecimalScale={true}
                prefix={'R$ '}
                placeholder="Digite o valor"
                allowNegative={false}
                value={precoMin}
                isNumericString={true}
              />
            </>)}
              
            
                <Slider 
                  defaultValue={30}
                  getAriaValueText={valuetext}
                  onChange={(e) => setPreco(e.target.value)}
                  valueLabelDisplay="auto" 
                  shiftStep={30}
                  step={step}
                  sx={{color: '#2c2c2d'}}
                  marks
                  size={isMobile ? 'small' : 'medium'}
                  min={precoMin}
                  max={precoMax}
                />
                {!isMobile && (<>
                
                  <label>Máx</label>
                  <NumericFormat
                    thousandSeparator={true}
                    decimalScale={2}
                    onChange={(e) => handleRange(e.target.value, true)}
                    fixedDecimalScale={true}
                    prefix={'R$ '}
                    placeholder="Digite o valor máximo"
                    allowNegative={false}
                    value={precoMax}
                    isNumericString={true}
                  />
                </>)}
                
     
            </div>
            
    
          
          </Typography> 
          <hr></hr>
          <Typography  sx={{fontSize: !isMobile ? '1.25rem' : '1rem'}} gutterBottom>
                <FontAwesomeIcon icon={faList}></FontAwesomeIcon>          Categorias 
                <FontAwesomeIcon style={{marginLeft: '10px', cursor: 'pointer'}} icon={faRefresh}></FontAwesomeIcon>
          </Typography>
          <Typography gutterBottom>
            <div className="conjunto-botoes-categorias">
                {
                    categorias?.map((categoria) => 
                        (
                            <>
                                <div>
                                    <Button sx={
                                      {
                                        outline: 'none !important'
                                      }
                                    } color="secondary" onClick={() => {setTipoId(categoria.id)}} className="categoria-button" variant={tipoId === categoria.id ? 'contained' : 'outlined' }><span>{categoria.nome}</span></Button>
                                </div>
                            </> 
                        )
                    )
                }
            </div>
          
          </Typography> 

          <hr></hr>
          <Typography  sx={{fontSize: !isMobile ? '1.25rem' : '1rem'}} gutterBottom>
                <FontAwesomeIcon icon={faList}></FontAwesomeIcon>          Ordenação 
                {order ? <>{desc ? <><FontAwesomeIcon style={{marginLeft: '10px', cursor: 'pointer'}} onClick={() => handleOrder(false)} icon={faArrowUpWideShort}></FontAwesomeIcon></> : <><FontAwesomeIcon  style={{marginLeft: '10px', cursor: 'pointer'}} onClick={() => handleOrder(true)} icon={faArrowDownWideShort}></FontAwesomeIcon></>}
                </> : <></>}
          </Typography>
          <Typography gutterBottom>
            <div className="conjunto-botoes-categorias">
                {
                    categoriasFiltro?.map((categoria) => 
                        (
                            <>
                                <div>
                                    <Button sx={
                                      {
                                        outline: 'none !important'
                                      }
                                    } color="secondary" onClick={() => {setOrder(categoria)}} className="categoria-button" variant={order === categoria ? 'contained' : 'outlined' }><span>{categoria}</span></Button>
                                </div>
                            </> 
                        )
                    )
                }
            </div>
          
          </Typography> 
        </DialogContent>
        <DialogActions sx={{backgroundColor: '#282828', color: 'white', justifyContent:'center'}}>
        <Button sx={{outline: 'none !important'}} variant="contained" color="info" autoFocus onClick={() => {handleClose(); obterProdutos(true, true)}}>
            Limpar Busca <FontAwesomeIcon style={{marginLeft: '15px'}} icon={faBan}></FontAwesomeIcon>
          </Button>
          <Button sx={{outline: 'none !important'}} variant="contained" color="secondary" autoFocus onClick={() => {handleClose(); obterProdutos()}}>
            Buscar <FontAwesomeIcon style={{marginLeft: '15px'}} icon={faSearch}></FontAwesomeIcon>
          </Button>
         
        </DialogActions>
      </Dialog>


      <Dialog
        open={openAddCarrinho} 
        keepMounted
        onClose={handleCloseCarrinho}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Adicionar Produto ao Carrinho"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <p> {produtoCarrinho?.nome}</p>
            <p> Essa ação adicionará o produto ao seu carrinho. Deseja confirmar? </p>
            <p> Dados de quantidade poderão ser alterados dentro do carrinho.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCarrinho}>Cancelar</Button>
          <Button onClick={() => adicionarAoCarrinho(produtoCarrinho)}>Continuar</Button>
        </DialogActions>
      </Dialog>

            
            <div id="search-group">
              <div id="nav-search" >
                
                <div className="gears">
                  {order ? <> 
                    {desc  ? <><FontAwesomeIcon onClick={() => handleOrder(false)} icon={faArrowUpWideShort}></FontAwesomeIcon></> : <><FontAwesomeIcon onClick={() => handleOrder(true)} icon={faArrowDownWideShort}></FontAwesomeIcon></>}
                   </> : <></>}
                    <FontAwesomeIcon onClick={handleClickOpen} icon={faGear}></FontAwesomeIcon>
                    <FontAwesomeIcon onClick={obterProdutos} icon={faTrash}></FontAwesomeIcon>
                </div>
                
                <div className="conjunto-pesquisa">
                    <input value={nome} onChange={(e) => setNome(e.target.value)}/>
                    <FontAwesomeIcon onClick={() =>  {obterProdutos(false, true) }} style={{cursor: 'pointer'}} id='lupa' icon={faSearch} />
                </div>
                <div>
                  
                </div>
              </div>
              <div id='nav-pagination'>
                {!loading && (<>
                  <Pagination size={isMobile ? 'small' : 'medium'} className="paginator" page={pagina} onChange={(a, b) => setPagina(b)} sx={{paddingTop: '0px !important', color: 'white !important'}} count={totalCount} variant="outlined" color="secondary" />
                </>)}
              
              </div>
            </div>
            

        

            <div className="produtoContainer">
                {   
                    loading ? <><CircularProgress color="secondary" style={{marginTop: '20px'}}></CircularProgress></> :
                    produtos.map((produto) => (<>
                        <ProductComponent setProdutoCarrinho={setProdutoCarrinho} isMobile={isMobile} registro={produto}></ProductComponent>
                    </>))
                }
            </div>

            
        
    </>)
}

export default Orders