
import * as React from 'react';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import sparttanisAxios from '../../../global/SparttanisAxios';

function ContadorPedidos ({registro})  {
    const [count, setCount] = React.useState(1); 

    React.useEffect(() => {  
 
      if (count >= 1) {
        mudarQuantidadeDoPedido()
      }

    }, [count])

    React.useEffect(() =>{
        setCount(registro.quantidade)
    }, [registro])

    const mudarQuantidadeDoPedido = async () => {
      try {
        await sparttanisAxios.put(`/pedido-qtd/${registro.id}/${count}`)
     
      } catch (e) {

      } finally {
        const eventoPersonalizado = new CustomEvent('recarregarCarrinho', {
          detail: { mensagem: 'recarregar' }
        });
        document.dispatchEvent(eventoPersonalizado)
      }
    }
    
    return (
        <Box
          sx={{
            color: 'action.active',
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
              marginBottom: 2,
            },
            '& .MuiBadge-root': {
              marginRight: 4,
            },
          }}
        >
          <div>
            <Badge color="secondary" badgeContent={count}>
              <Inventory2Icon  sx={{color: 'white'}}/>
            </Badge>
            <ButtonGroup>
              <Button
                color='secondary'
                aria-label="reduce"
                onClick={() => {
                  
                  if (count > 1) {
                    setCount(Math.max(count - 1, 0));
                  } else {
                    setCount(1)
                  }
                 
                }}
              >
                <RemoveIcon fontSize="small" />
              </Button>
              <Button
                color='secondary'
                aria-label="increase"
                onClick={() => { 
                  setCount(count + 1);
                }}
              >
                <AddIcon fontSize="small" />
              </Button>
            </ButtonGroup>
          </div>
           
        </Box>
      );
}


export default ContadorPedidos