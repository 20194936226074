import React from 'react';
import { Button } from '@mui/material'
function SD0RealizarPedido() {
    function generateRandomSequence() {
        const characters = 'ABCDEFGHIJKLMN0123456789';
        let sequence = '';
        for (let i = 0; i < 6; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            sequence += characters[randomIndex];
        }
        return sequence;
    }
    
    function startGeneratingSequences() {
        const p1 = document.getElementById('p1')
        const p2 = document.getElementById('p2')
        const p3 = document.getElementById('p3')
        setInterval(() => {
            const randomSequence = generateRandomSequence(); 
            p1.textContent = 'R$ ' +  randomSequence
        }, 30);
        setInterval(() => {
            const randomSequence = generateRandomSequence(); 
            p2.textContent = 'R$ ' + randomSequence
        }, 30);
        setInterval(() => {
          const randomSequence = generateRandomSequence(); 
          p3.textContent = 'R$ ' + randomSequence
      }, 30);
    }

    React.useEffect(() => {
        startGeneratingSequences()
    }, [])

    return (<>
          <div id='SD0-BUY'>
            <span><span className='disponibilidade'>Disponibilidade:</span> <span className='yellow'>em breve</span></span>
            <span><span id='p1' className='green pricing'></span> <span>cartão em 5x de <span id='p3'></span> sem juros</span></span>
            <span style={{flexDirection: 'column'}}><span id='p2' className='green pricing'></span> <span style={{paddingLeft: '10px'}}>no pix</span></span>
            <div className='full-width realizarPedido'>
              <div className='full-width'>
                <span>Frete e prazo (CEP):</span>
                <input placeholder='00000-000' className='full-width'></input>
              </div>
              <div className='full-width'>
                <span>Cupom:</span>
                <input placeholder='0000' className='full-width'></input>
              </div>
              <Button className='full-width' sx={{opacity: '1 !important'}} variant='contained' color="info">Em Breve</Button>
            </div>
          </div>
    </>)
}

export default SD0RealizarPedido