import sparttanisAxios from "../../global/SparttanisAxios"
import React from "react"  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan} from '@fortawesome/free-solid-svg-icons';
 
import './PerfilDialog.css'
import Button from '@mui/material/Button'; 
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';  
import { isMobile } from 'react-device-detect';
import { useState } from "react";
import InputMask from 'react-input-mask';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './PerfilDialog.css'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SaveIcon from '@mui/icons-material/Save';
import dayjs from "dayjs";
const estados = [
    {"name": "Acre", "uf": "AC"},
    {"name": "Alagoas", "uf": "AL"},
    {"name": "Amapá", "uf": "AP"},
    {"name": "Amazonas", "uf": "AM"},
    {"name": "Bahia", "uf": "BA"},
    {"name": "Ceará", "uf": "CE"},
    {"name": "Distrito Federal", "uf": "DF"},
    {"name": "Espírito Santo", "uf": "ES"},
    {"name": "Goiás", "uf": "GO"},
    {"name": "Maranhão", "uf": "MA"},
    {"name": "Mato Grosso", "uf": "MT"},
    {"name": "Mato Grosso do Sul", "uf": "MS"},
    {"name": "Minas Gerais", "uf": "MG"},
    {"name": "Pará", "uf": "PA"},
    {"name": "Paraíba", "uf": "PB"},
    {"name": "Paraná", "uf": "PR"},
    {"name": "Pernambuco", "uf": "PE"},
    {"name": "Piauí", "uf": "PI"},
    {"name": "Rio de Janeiro", "uf": "RJ"},
    {"name": "Rio Grande do Norte", "uf": "RN"},
    {"name": "Rio Grande do Sul", "uf": "RS"},
    {"name": "Rondônia", "uf": "RO"},
    {"name": "Roraima", "uf": "RR"},
    {"name": "Santa Catarina", "uf": "SC"},
    {"name": "São Paulo", "uf": "SP"},
    {"name": "Sergipe", "uf": "SE"},
    {"name": "Tocantins", "uf": "TO"}
  ]


function PerfilDialog({setAbrirModal, abrirModal, chamarNotificacaoInfo, chamarNotificacaoSucesso, chamarNotificacaoErro}) {
const [open, setOpen] = React.useState(false);
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [cpf, setCpf] = useState('');  
    const [estaSalvando, setEstaSalvando] = useState(false)
    const [user, setUser] = useState({})
    const [dataNascimentoDayJs, setDataNascimentoDayJs] = useState('')
    const [dataNascimento, setDataNascimento] = useState('')
    const [telefone, setTelefone] = useState('')
    const [genero, setGenero] = useState('')
    const [generoSelect, setGeneroSelect] = useState('')
    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState(''); 

    React.useEffect(() => {
      (async () => {
        try {
          setUser((await sparttanisAxios.get('/user-info?id=' + localStorage.getItem('ntt'))).data)
        } catch(e) {}
      })()
    }, [])

    React.useEffect(() => {
      if (user && user.id) {
        setEmail(user.email || '');
        setUserName(`${user.firstName || ''} ${user.lastName || ''}`); 
        setPassword('');
        setConfirmPassword('');
        setCpf(user.attributes?.cpf?.[0] || ''); 
        setEstaSalvando(false);
        setDataNascimento(user.attributes?.dataNascimento?.[0] ? new Date(user.attributes.dataNascimento[0]) : '');
        setDataNascimentoDayJs(user.attributes?.dataNascimento?.[0] ? dayjs(new Date(user.attributes.dataNascimento[0])) : '');
        setTelefone(user.attributes?.telefone?.[0] || '');
        setGenero(user.attributes?.genero?.[0] || '');
        setGeneroSelect(user.attributes?.genero?.[0] || '');
        setCep(user.attributes?.cep?.[0] || '');
        setRua(user.attributes?.logradouro?.[0] || '');
        setNumero(user.attributes?.numero?.[0] || '');
        setBairro(user.attributes?.bairro?.[0] || '');
        setComplemento(user.attributes?.complemento?.[0] || '');
        setCidade(user.attributes?.cidade?.[0] || '');
        setEstado({
          uf: user.attributes?.estado?.[0] || ''
        });
      }
    }, [user]);

    React.useEffect(() => {
        if (abrirModal) {
            setOpen(true)
        } else {
            setOpen(false)
        }
    }, [abrirModal])

    const handleClose = () => {
        setOpen(false);
        setAbrirModal(false)
      };
      

    const handleClick = async (e) => {

        if (!cep) {
        chamarNotificacaoInfo('CEP incompleto')
        return
        }

        if (!rua) {
        chamarNotificacaoInfo('Logradouro incompleto')
        return
        }

        if (!numero) {
        chamarNotificacaoInfo('Número incompleto')
        return
        }

        if (!bairro) {
        chamarNotificacaoInfo('Bairro incompleto')
        return
        }

        if (!estado) {
        chamarNotificacaoInfo('Estado incompleto')
        return
        }

        if (!cidade) {
        chamarNotificacaoInfo('Cidade incompleta')
        return
        }
    
        if (!cpf) {
        chamarNotificacaoInfo('CPF incompleto')
        return
        }

        if (!genero) {
        chamarNotificacaoInfo('Gênero não informado')
        return
        }

        if (!dataNascimento) {
        chamarNotificacaoInfo('Data de Nascimento não informada')
        return
        }

        if (!telefone) {
        chamarNotificacaoInfo('Telefone não informado')
        return
        }
    
        if (!email || email?.split('@').length < 2) {
        chamarNotificacaoInfo('E-mail incompleto!')
        return
        }

        if (!userName) {
        chamarNotificacaoInfo('Nome incompleto!')
        return
        } 

        if (!password !== !confirmPassword) {
        chamarNotificacaoInfo('Senhas não coincidem')
        return
        }

        const splitada = userName.split(' ')
        const objetoUsuario = {
        "id": localStorage.getItem('ntt'),
        "username": userName.replace(/\s/g, ''),
        "email": email,
        "firstName": splitada[0],
        "lastName": splitada.length > 0 ? userName.split(splitada[0])[1] : 'wt',
        "password": password
        }

        objetoUsuario.attributes = {
        cpf: [cpf],
        dataNascimento: [dataNascimento],
        telefone: [telefone],
        genero: [genero],
        cep: [cep],
        logradouro: [rua],
        numero: [numero],
        bairro: [bairro],
        complemento: [complemento],
        estado: [estado.uf],
        cidade: [cidade]
        }

        handleSubmit(objetoUsuario)      
    }

    const handleChange = (e) => {
        const selectedUf = e.target.value;
        const selectedEstado = estados.find((es) => es.uf === selectedUf);
        setEstado(selectedEstado);
    };

    const resetState = () => { 
      setEmail('');
      setUserName('');
      setPassword('');
      setConfirmPassword('');
      setCpf(''); 
      setEstaSalvando(false);
      setUser({});
      setDataNascimento('');
      setTelefone('');
      setGenero('');
      setCep('');
      setRua('');
      setNumero('');
      setBairro('');
      setComplemento('');
      setCidade('');
      setEstado('');
    };

    const handleSubmit = async (user) => {

      try {
        setEstaSalvando(true) 
        if (!user?.password) {
          delete user.password
        } else {
         /*user.credentials = {
            "type": "password",
            "value": user.password,
            "temporary": false
          }*/
        }
        const response = await sparttanisAxios.put('/update', user);
        const eventoPersonalizadoCarrinho = new CustomEvent('sucesso', {
          detail: { mensagem: 'Perfil Atualizado Com Sucesso!' }
        });
        document.dispatchEvent(eventoPersonalizadoCarrinho) 
        //resetState() 
      } catch (error) { 
        setEstaSalvando(false) 
        const eventoPersonalizadoCarrinho = new CustomEvent('sucesso', {
          detail: { mensagem: 'Erro ao atualizar perfil' }
        });
        document.dispatchEvent(eventoPersonalizadoCarrinho) 
      }
    };
  

    return (
        <>
    <Dialog  
        fullScreen={isMobile}
        maxWidth={1600} 
        open={open}
        onClose={handleClose}
    >
        <DialogTitle sx={{ m: 0, p: 2, background: '#282828', color: 'white' }} id="customized-dialog-title">
            Meu Perfil <AccountCircleIcon></AccountCircleIcon>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{background: '#F9EDE0'}} dividers>
          
          <div className="fullCentered">

          <Button sx={{outline: 'none !important'}} variant="contained" color="info" autoFocus onClick={() => {handleClose(); }}>
            Cancelar <FontAwesomeIcon style={{marginLeft: '15px'}} icon={faBan}></FontAwesomeIcon>
          </Button>
          <Button sx={{outline: 'none !important'}} variant="contained" color="secondary" autoFocus onClick={() => {handleClick();}}>
            Salvar <SaveIcon sx={{marginLeft: '10px'}}></SaveIcon>
          </Button>
          </div>
          <hr/>
 
          <div style={{
            minWidth: isMobile? '200px' : '1600px'
          }}>


        <Typography id='conjunto-formulario-perfil' sx={{fontSize: !isMobile ? '1.25rem' : '1rem', display: 'flex', flexWrap: 'wrap'}} gutterBottom>

           
          
        <div className="col-12   col-xl-6">
          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="col-12   col-xl-6">
          <label htmlFor="email">Nome Completo</label>
          <input
            type="text"
            id="usn"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </div>
        {/*<div className="col-12   col-xl-6">
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="col-12   col-xl-6">
          <label htmlFor="password">Confirmar Senha</label>
          <input
            type="password"
            id="password2"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>*/} 

 
          <>
        
          <div className="col-12   col-xl-6">
            <label>CPF</label>
            <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            >     
              {() => 
                <input
                  type="text"
                  id="cpf"
                  
                  required
                />
              }
            </InputMask>
          </div>
          <div className="col-12   col-xl-6">
            <label>Gênero</label>
            <select
              id="gender"
              name="gender" 
              className='select-form'
              value={genero}
              onChange={(e) =>  {setGenero(e.target.value); setGeneroSelect({uf: e.target.value});}}
            >
              <option value="">Selecione</option>
              <option value="male">Masculino</option>
              <option value="female">Feminino</option>
              <option value="nonbinary">Não-Binário</option>
              <option value="other">Outro</option>
            </select>
          </div>
          <div className="col-12   col-xl-6">
            <label>Data de Nascimento</label>
            {dataNascimentoDayJs && (<>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
      
                <DatePicker value={dataNascimentoDayJs} onChange={(e) => setDataNascimento(new Date(e['$d']))} className='dataNascimentoPerfil datepicker-spt'/>

              </LocalizationProvider>
            </>)}
            
          </div>
          <div className="col-12   col-xl-6">
            <label>Telefone</label>
            <InputMask
              mask="(99) 99999-9999"
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
            > 
            
            {() => 
                  <input
                    type="text"
                    id="telefone"
                    
                    required
                  />
            }

            </InputMask>
          </div>
          </>
      
   
            <>  
              <div className="col-12   col-xl-6">
                <label htmlFor="email">CEP</label>
                <InputMask
                  mask="99999-999"
                  value={cep}
                  onChange={(e) => setCep(e.target.value)}
                >{() => <input
                  type="text"
                  id="cpf"
                  required
                />}</InputMask>
              </div>
              <div className="col-12   col-xl-6">
                <label htmlFor="email">Logradouro</label>
                <input
                  type="text"
                  id="rua"
                  value={rua}
                  onChange={(e) => setRua(e.target.value)}
                  required
                />
              </div>
              <div className="col-12   col-xl-6"> 
                <label htmlFor="email">Número</label>
                <input
                  type="text"
                  id="numero"
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                  required
                />
              </div>
              <div className="col-12   col-xl-6">
                <label htmlFor="email">Bairro</label>
                <input
                  type="text"
                  id="bairro"
                  value={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                  required
                />
              </div>
              <div className="col-12   col-xl-6">
                <label htmlFor="email">Complemento</label>
                <input
                  type="text"
                  id="complemento"
                  value={complemento}
                  onChange={(e) => setComplemento(e.target.value)}
                  required
                />
              </div>
              <div className="col-12   col-xl-6">
                <label htmlFor="email">Estado</label>
                  
                <select
                  id="estado"
                  name="estado"
                  className='select-form'
                  value={estado.uf}
                  onChange={handleChange}
                >
                  <option value="">Selecione</option>
                  {
                    estados.map((estado) => (
                      <option key={estado.uf} value={estado.uf}>{estado.name}</option>
                    ))
                  }
              </select>
            </div>
              <div className="col-12   col-xl-6">
                <label htmlFor="email">Cidade</label>
                <input
                  type="text"
                  id="cidade"
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                  required
                />
              </div>
            </>
           
            </Typography> 
          <hr></hr>
      
          
          </div>
        </DialogContent>
        <DialogActions sx={{backgroundColor: '#282828', color: 'white', justifyContent:'center'}}>
        <Button sx={{outline: 'none !important'}} variant="contained" color="info" autoFocus onClick={() => {handleClose(); }}>
            Cancelar <FontAwesomeIcon style={{marginLeft: '15px'}} icon={faBan}></FontAwesomeIcon>
          </Button>
          <Button sx={{outline: 'none !important'}} variant="contained" color="secondary" autoFocus onClick={() => {handleClick();}}>
            Salvar <SaveIcon sx={{marginLeft: '10px'}}></SaveIcon>
          </Button>
         
        </DialogActions>
      </Dialog>
        </>
    )
}

export default PerfilDialog