import React, { useState, useEffect } from "react";
import sparttanisAxios from "../../global/SparttanisAxios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Delete } from "@mui/icons-material";
import { isMobile } from "react-device-detect";
import "./HistoricoDialog.css";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';  
import ListAltIcon from '@mui/icons-material/ListAlt';
function HistoricoDialog({ setAbrirModal, abrirModal, chamarNotificacaoInfo, chamarNotificacaoSucesso, chamarNotificacaoErro }) {
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [carrinho, setCarrinho] = useState()
  useEffect(() => {
    obterPedidosDoCarrinho();

    document.addEventListener('recarregarCarrinho', obterPedidosDoCarrinho)

    return (() => {
      document.removeEventListener('recarregarCarrinho', obterPedidosDoCarrinho)
    })
  }, []);

  useEffect(() => {
    setOpen(abrirModal);
  }, [abrirModal]);

  const obterPedidosDoCarrinho = async () => {
    try {
      const response = await sparttanisAxios.post(`/historico-pedidos/${localStorage.getItem("ntt")}`);
      const dados = response.data;
      if (dados?.length > 0) {
        const array = dados.flatMap(d => d.Pedido.map((d2) => {
          return {
            ...d2,
            dtCriacao: d.dtCriacao
          }
        }))
      
        setPedidos(array);
      }
    
    } catch (error) {
      console.error("Erro ao obter pedidos:", error);
      const eventoPersonalizado = new CustomEvent('erro', {
        detail: { mensagem: 'Não foi possível obter o histórico de pedidos.' }
      });
      document.dispatchEvent(eventoPersonalizado)
    }
  };

  const handleClose = () => {
    setOpen(false);
    setAbrirModal(false);
  };

  const formatarPreco = (numero) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numero);
  };

  const removerDoCarrinho = async (registro) => { 
    try {
      setLoading(true)
      await sparttanisAxios.post('/pedido-remove/' + registro.id + '/' + carrinho.id)
      setLoading(false)
      obterPedidosDoCarrinho()
      
    } catch (e) {
      setLoading(false)
      const eventoPersonalizado = new CustomEvent('erro', {
        detail: { mensagem: 'Não foi possível remover o produto do carrinho.' }
      });
      document.dispatchEvent(eventoPersonalizado)
    }
    
  };

  function formatDate(pedido) { 
    const date =  new Date(pedido.dtCriacao)
    
    if (!date) {
      return ''
    }
    if (!(date instanceof Date)) {
      //console.log('O parâmetro deve ser uma instância de Date.');
    }
  
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses começam em 0
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }

  return (
    <Dialog fullScreen={isMobile} maxWidth="lg" open={open} onClose={handleClose}>
      <DialogTitle sx={{ m: 0, p: 2, background: "#282828", color: "white" }}>
        Histórico de Pedidos <ListAltIcon />
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ background: "#242220" }} dividers>
        <div style={{ display: "flex", color: "white" }}>
          {!loading ? (<>
            <table className="tabelaCarrinho" style={{ width: isMobile ? "300px" : "1200px" }}>
            <thead>
              <tr>

                <th>Data</th>
                <th>Produto</th>
                <th>Quantidade</th>
                <th>Preço</th> 
      
              </tr>
            </thead>
            <tbody>
              {pedidos.map((pedido) => (
                <tr key={pedido.id}>
                  <td>
                    {formatDate(pedido)}
                  </td>
                  <td>{pedido.Produto?.nome}</td>
                  <td>{pedido.quantidade} </td>
                  <td>{formatarPreco(pedido.Produto?.preco)}</td>
              
                </tr>
              ))}
            </tbody>
          </table>
          </>) : <>
                <span><h2>Carregando...</h2></span>
          </>}
         
        </div>
      </DialogContent>
      {/*<div>
        <LocalShippingIcon></LocalShippingIcon>
        <span>O endereço de entrega é o que consta em seu cadastro. Você pode alterá-lo em seu perfil ou durante o nosso contato.</span>
              </div>*/}
      <DialogActions sx={{ backgroundColor: "#282828", color: "white", justifyContent: "center" }}>
       
      </DialogActions>
    </Dialog>
  );
}

export default HistoricoDialog;
