import { useNavigate } from 'react-router-dom';
import AreaTecnica from '../components/AreaTecnica';
import CircleIcon from '@mui/icons-material/Circle';
import SD0RealizarPedido from '../components/SD0RealizarPedido';

function SDContainer() {
  const navigate = useNavigate()

    return <>

      <div id='imgs' className='sdContainer'>
        <img className='sdImg' alt='sd1' src='orange.png' onClick={() => navigate('/sd0-more')}></img>
        <img className='sdImg' alt='sd2' src='sdimpressora.png'></img>
      </div>


      <div id="mobile" className='full-width full-centered' style={{display: 'flex', flexDirection: 'column' }}>
        <img height={350} alt="ginfo" src='/general-info.png'></img>
        <img style={{marginTop: '25px'}} height={100} alt='alt' src='/SDINFORECTANGLE.png'></img>

        <div>
        <div className="compreAqui" style={{marginBottom: '20px', marginTop: '30px'}}>Área de Compra</div>
          <img src='plate.png' alt='plate' className='plateVejaMais' ></img>
          <div className='full-width full-centered' style={{'padding': '10px'}}></div>
          <div className='full-width full-centered' style={{'padding': '10px'}}>
            <div className='caracteristicasMobile full-width'  style={{marginBottom: '20px'}}>
                <span>Principais Características</span>
                <div>
                  <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Wifi</span></ul>
                  <ul><CircleIcon sx={{height: '12px'}}></CircleIcon>  <span>Bluetooth</span></ul>
                  <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Antena NRF</span></ul>
                  <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Acelerômetro</span></ul>
                  <ul><CircleIcon sx={{height: '12px'}}></CircleIcon>  <span>Giroscópio</span></ul>
                  <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Temperatura</span></ul>
                  <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Umidade</span></ul>
                  <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Buzzer</span></ul>
                  <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Suporta até 5 servo-motores</span></ul>
                </div>
            </div>
          </div>
          <div className='compreAquiSquare full-width full-centered' style={{'padding': '10px'}}>
            <SD0RealizarPedido></SD0RealizarPedido>
          </div>
          
        </div>
        <div className="compreAqui" style={{marginBottom: '20px', marginTop: '30px'}}>Área Técnica</div>
        <AreaTecnica></AreaTecnica>

        
      </div>
    </>
}

export default SDContainer