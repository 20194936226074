  import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
  import './App.css'; 
  import '@fontsource/roboto/300.css';
  import '@fontsource/roboto/400.css';
  import '@fontsource/roboto/500.css';
  import '@fontsource/roboto/700.css'; 
  import './App.css'; 
  import '@fontsource/roboto/300.css';
  import '@fontsource/roboto/400.css';
  import '@fontsource/roboto/500.css';
  import '@fontsource/roboto/700.css';

  import Container from './pages/Container';
  import SDContainer from './pages/SDContainer';
  import VejaMaisSDContainer from './pages/VejaMaisSDContainer';
  import Orders from './pages/ecommerce/Orders';
  import Login from './pages/Login/Login';
  import 'react-toastify/dist/ReactToastify.css';
  import { ToastContainer, toast } from 'react-toastify';
  import CreateAccount from './pages/CreateAccount/CreateAccount';
  import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

  const ConditionalContainer = () => {
    const location = useLocation();
  
    if (!location.pathname.includes('acesso') && !location.pathname.includes('criar-conta')) {
      return <Container />;
    }
    return null;
  };

  function App() {

    useEffect(() => {
      const handleInfo = (e) => {
        chamarNotificacaoInfo(e.detail.mensagem)
      }
      const handleSucesso = (e) => { 
        chamarNotificacaoSucesso(e.detail.mensagem)
      }
      const handleErro = (e) => {
        chamarNotificacaoErro(e.detail.mensagem)
      }
      document.addEventListener('sucesso', handleSucesso)
      document.addEventListener('info', handleInfo)
      document.addEventListener('erro', handleErro)

      return (() => {
        document.removeEventListener('sucesso', handleSucesso)
        document.removeEventListener('info', handleInfo)
        document.removeEventListener('erro', handleErro)
      })
    }, [])
     
    function chamarNotificacaoInfo(mensagem) {
      toast.info(mensagem, {containerId: 'toastAppjs'})
    }
    function chamarNotificacaoSucesso(mensagem) {
      toast.success(mensagem, {containerId: 'toastAppjs'})
    }
    function chamarNotificacaoErro(mensagem) {
      toast.error(mensagem, {containerId: 'toastAppjs'})
    }
    return (
      <div className="App">
      <Router>
        <ConditionalContainer></ConditionalContainer>
          
          <Routes>
              <Route path="/" element={<SDContainer />} /> 
              <Route path="/orders" element={<Orders />} />
              <Route path="/acesso" element={<Login chamarNotificacaoInfo={chamarNotificacaoInfo}></Login>} />
              <Route path='/criar-conta' element={<CreateAccount chamarNotificacaoErro={chamarNotificacaoErro} chamarNotificacaoSucesso={chamarNotificacaoSucesso} chamarNotificacaoInfo={chamarNotificacaoInfo}></CreateAccount>} /> 
              <Route path="/sd0-more" element={<VejaMaisSDContainer />} /> 
          </Routes>
          <ToastContainer containerId={'toastAppjs'} position='top-center' />
      </Router>
      </div>
    );
  }

  export default App;
