import CircleIcon from '@mui/icons-material/Circle';

function SD0Caracteristicas() {

    return (<>
         <div id='caracteristicassd0'>
            <div className="compreAqui">SD-ZERO</div>
            <span style={{fontWeight: '300'}}>SD-ZERO é a melhor plataforma para seus projetos.</span>
            <div style={{marginTop: '20px'}}>
              <span>Principais Características</span>
              <div>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Wifi</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon>  <span>Bluetooth</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Antena NRF</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Acelerômetro</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon>  <span>Giroscópio</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Temperatura</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Umidade</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Buzzer</span></ul>
                <ul><CircleIcon sx={{height: '12px'}}></CircleIcon> <span>Suporta até 5 servo-motores</span></ul>
              </div>
            </div>
          </div>
    </>)
}

export default SD0Caracteristicas