import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import sparttanisAxios from '../../global/SparttanisAxios';
import './Login.css';
import { Button } from '@mui/material';


function Login({chamarNotificacaoInfo}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  React.useEffect(() => {
    // Change the background color when the component is mounted
 
    document.getElementById('body').style.backgroundColor = 'rgb(15 15 15)' 
    // Clean up function to reset the background color when the component is unmounted
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await sparttanisAxios.post('/login', { username: email, password });
      // Handle successful login 
      localStorage.setItem('token', response?.data?.token)
      localStorage.setItem('name', response?.data?.name)
      localStorage.setItem('ntt', response?.data?.sub)
      navigate('/'); 
    } catch (error) { 
      setError('Login failed. Please check your credentials and try again.');
    }
  };

  return (
    <div id="login-card">
      <span><img id="logoLogin" src='/logo.png'></img></span>
 
      <form id="acesso-form" onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className='container-botoes'>
          <Button id='btlg' onClick={handleSubmit} variant='contained' sx={{width: '300px'}} color='secondary' >Acessar</Button>
          <Button variant='contained'  onClick={() => {navigate('/criar-conta')}} sx={{width: '300px'}} color='secondary' >Criar Conta</Button>
        </div>
        <div className="links"> 
        <Link to="/forgot-password" style={{color: 'white' }}>Esqueceu sua senha?</Link>
      </div>
      </form>
     
    </div>
  );
}

export default Login;
