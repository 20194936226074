import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import sparttanisAxios from '../../global/SparttanisAxios';
import './CreateAccount.css';
import { Button } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import InputMask from 'react-input-mask';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/pt-br'; 
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from 'axios';
import SetaDireita from '@mui/icons-material/KeyboardDoubleArrowRight';
import SetaEsquerda from '@mui/icons-material/KeyboardDoubleArrowLeft';
import CircularProgress from '@mui/material';
const estados = [
  {"name": "Acre", "uf": "AC"},
  {"name": "Alagoas", "uf": "AL"},
  {"name": "Amapá", "uf": "AP"},
  {"name": "Amazonas", "uf": "AM"},
  {"name": "Bahia", "uf": "BA"},
  {"name": "Ceará", "uf": "CE"},
  {"name": "Distrito Federal", "uf": "DF"},
  {"name": "Espírito Santo", "uf": "ES"},
  {"name": "Goiás", "uf": "GO"},
  {"name": "Maranhão", "uf": "MA"},
  {"name": "Mato Grosso", "uf": "MT"},
  {"name": "Mato Grosso do Sul", "uf": "MS"},
  {"name": "Minas Gerais", "uf": "MG"},
  {"name": "Pará", "uf": "PA"},
  {"name": "Paraíba", "uf": "PB"},
  {"name": "Paraná", "uf": "PR"},
  {"name": "Pernambuco", "uf": "PE"},
  {"name": "Piauí", "uf": "PI"},
  {"name": "Rio de Janeiro", "uf": "RJ"},
  {"name": "Rio Grande do Norte", "uf": "RN"},
  {"name": "Rio Grande do Sul", "uf": "RS"},
  {"name": "Rondônia", "uf": "RO"},
  {"name": "Roraima", "uf": "RR"},
  {"name": "Santa Catarina", "uf": "SC"},
  {"name": "São Paulo", "uf": "SP"},
  {"name": "Sergipe", "uf": "SE"},
  {"name": "Tocantins", "uf": "TO"}
]


function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress  variant="determinate" color='secondary' {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}


function CreateAccount({chamarNotificacaoInfo, chamarNotificacaoSucesso, chamarNotificacaoErro}) {
  const [progress, setProgress] = React.useState(0);
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [cpf, setCpf] = useState(''); 
  const [passo, setPasso] = useState(0)
  const [podeSalvar, setPodeSalvar] = useState(false)
  const [estaSalvando, setEstaSalvando] = useState(false)
  const [user, setUser] = useState({})
  const [dataNascimento, setDataNascimento] = useState('')
  const [telefone, setTelefone] = useState('')
  const [genero, setGenero] = useState('')

  const [cep, setCep] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState(''); 
  const navigate = useNavigate();

  const resetState = () => {
    setProgress(0);
    setEmail('');
    setUserName('');
    setPassword('');
    setConfirmPassword('');
    setCpf('');
    setPasso(0);
    setPodeSalvar(false);
    setEstaSalvando(false);
    setUser({});
    setDataNascimento('');
    setTelefone('');
    setGenero('');
    setCep('');
    setRua('');
    setNumero('');
    setBairro('');
    setComplemento('');
    setCidade('');
    setEstado('');
  };

  React.useEffect(() => {
    // Change the background color when the component is mounted
    
    document.getElementById('body').style.backgroundColor = 'rgb(15 15 15)' 
    // Clean up function to reset the background color when the component is unmounted
    return () => {
      setUser({})
      setUserName('')
      setPassword('')
      setConfirmPassword('')
      setPasso(0)
      document.body.style.backgroundColor = '';
    }; 
  }, []);

  React.useEffect(() => {
    if (user?.email && passo === 0) {
      setProgress(40)
      setPasso(1)
    } else if (!user?.email) {
      setPasso(0)
    }
  }, [user])


  const handleClick = async (e) => {
    e.preventDefault();

    if (podeSalvar) {
      const objetoUsuario = user
      objetoUsuario.attributes = {
        cpf: [cpf],
        dataNascimento: [dataNascimento],
        telefone: [telefone],
        genero: [genero],
        cep: [cep],
        logradouro: [rua],
        numero: [numero],
        bairro: [bairro],
        complemento: [complemento],
        estado: [estado.uf],
        cidade: [cidade]
      }

      handleSubmit(objetoUsuario)
      return
    }

    if (passo === 2) {
      
      if (!cep) {
        chamarNotificacaoInfo('CEP incompleto')
        return
      }

      if (!rua) {
        chamarNotificacaoInfo('Logradouro incompleto')
        return
      }

      if (!numero) {
        chamarNotificacaoInfo('Número incompleto')
        return
      }

      if (!bairro) {
        chamarNotificacaoInfo('Bairro incompleto')
        return
      }

      if (!estado) {
        chamarNotificacaoInfo('Estado incompleto')
        return
      }

      if (!cidade) {
        chamarNotificacaoInfo('Cidade incompleta')
        return
      }

      setPodeSalvar(true)

      setProgress(100)
      return
    }

    if (passo === 1) { 


      if (!cpf) {
        chamarNotificacaoInfo('CPF incompleto')
        return
      }

      if (!genero) {
        chamarNotificacaoInfo('Gênero não informado')
        return
      }

      if (!dataNascimento) {
        chamarNotificacaoInfo('Data de Nascimento não informada')
        return
      }

      if (!telefone) {
        chamarNotificacaoInfo('Telefone não informado')
        return
      }

      setProgress(70)
      setPasso(2)
      return
    }

    const splitada = userName.split(' ')
    
      if (!email || email?.split('@').length < 2) {
        chamarNotificacaoInfo('E-mail incompleto!')
        return
      }

      if (!userName) {
        chamarNotificacaoInfo('Nome incompleto!')
        return
      }

      if (!password || !confirmPassword) {
        chamarNotificacaoInfo('Dados de senha incompletos!')
        return
      }

      if (!password !== !confirmPassword) {
        chamarNotificacaoInfo('Senhas não coincidem')
        return
      }

      const obj = {
        "username": userName.replace(/\s/g, ''),
        "email": email,
        "firstName": splitada[0],
        "lastName": splitada.length > 0 ? userName.split(splitada[0])[1] : 'wt',
        "password": password
      }
      setUser(obj)
  }

  const handleSubmit = async (user) => {

    try {
      setEstaSalvando(true)
      const response = await sparttanisAxios.post('/register', user);
      chamarNotificacaoSucesso('Usuário Gerado Com Sucesso!')
      resetState()
      navigate('/acesso'); 
    } catch (error) { 
      setEstaSalvando(false)
      chamarNotificacaoErro('Erro ao gerar usuário. Verifique os campos ou se o cadastro já existe.')
    }
  };


  React.useEffect(() => {
    (async () => {
       if (cep) {
        const cepTratado = cep.replace(/\D/g, '')

        if (cepTratado?.length < 8) {
          return
        }
        
        try {
          const dadosCep = (await axios.get(`https://viacep.com.br/ws/${cepTratado}/json/`))?.data
          if (dadosCep) { 
            setBairro(dadosCep.bairro)
            setComplemento(dadosCep.complemento)
            setRua(dadosCep.logradouro)
            setCidade(dadosCep.cidade)
          }
        } catch(e) {
          
        }
      }
    })()
   
  }, [cep])


  const handleChange = (e) => {
    const selectedUf = e.target.value;
    const selectedEstado = estados.find((es) => es.uf === selectedUf);
    setEstado(selectedEstado);
  };


  return (
    <div id="login-card" style={{pointerEvents: estaSalvando  ? 'none' : 'all', opacity: estaSalvando ? 0.3 : 1}}>
      <span><img id="logoLogin" alt='logo' src='/logo.png'></img></span>
      <Box sx={{ width: '100%' }}>
        <LinearProgressWithLabel  value={progress} />
      </Box>
      <form id="login-form" onSubmit={handleSubmit}>
      {
        passo > 0 ? (
          <>
            <Button id='btlg' className='botao-voltar' onClick={() => {
              setPodeSalvar(false)
              if (passo === 2) {
                setProgress(40)
              }

              if (passo === 1) {
                setProgress(0)
              }

              setPasso(passo - 1)
            }} variant='contained' sx={{width: '300px'}} color='secondary' >
              <SetaEsquerda></SetaEsquerda>  <span>Voltar</span>
            </Button>
          </>
        ) : <></>
      }
      

        {passo === 0 && (<>
          
        <div>
          <label htmlFor="email">E-mail</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="email">Nome Completo</label>
          <input
            type="text"
            id="usn"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Senha</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Confirmar Senha</label>
          <input
            type="password"
            id="password2"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>

        </> ) }
        {passo === 1 && (
          <>
        
          <div>
            <label>CPF</label>
            <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={(e) => setCpf(e.target.value)}
            >     
              {() => 
                <input
                  type="text"
                  id="cpf"
                  
                  required
                />
              }
            </InputMask>
          </div>
          <div>
            <label>Gênero</label>
            <select
              id="gender"
              name="gender" 
              className='select'
              onChange={(e) => setGenero(e.target.value)}
            >
              <option value="">Selecione</option>
              <option value="male">Masculino</option>
              <option value="female">Feminino</option>
              <option value="nonbinary">Não-Binário</option>
              <option value="other">Outro</option>
            </select>
          </div>
          <div>
            <label>Data de Nascimento</label>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
      
             <DatePicker onChange={(e) => setDataNascimento(new Date(e['$d']))} className='datepicker-spt'/>

            </LocalizationProvider>
          </div>
          <div>
            <label>Telefone</label>
            <InputMask
              mask="(99) 99999-9999"
              value={telefone}
              onChange={(e) => setTelefone(e.target.value)}
            > 
            
            {() => 
                  <input
                    type="text"
                    id="telefone"
                    
                    required
                  />
            }

            </InputMask>
          </div>
          </>
        )}
        {
          passo === 2 && (
            <>  
              <div>
                <label htmlFor="email">CEP</label>
                <InputMask
                  mask="99999-999"
                  value={cep}
                  onChange={(e) => setCep(e.target.value)}
                >{() => <input
                  type="text"
                  id="cpf"
                  required
                />}</InputMask>
              </div>
              <div>
                <label htmlFor="email">Logradouro</label>
                <input
                  type="text"
                  id="rua"
                  value={rua}
                  onChange={(e) => setRua(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="email">Número</label>
                <input
                  type="text"
                  id="numero"
                  value={numero}
                  onChange={(e) => setNumero(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="email">Bairro</label>
                <input
                  type="text"
                  id="bairro"
                  value={bairro}
                  onChange={(e) => setBairro(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="email">Complemento</label>
                <input
                  type="text"
                  id="complemento"
                  value={complemento}
                  onChange={(e) => setComplemento(e.target.value)}
                  required
                />
              </div>
              <div>
                <label htmlFor="email">Estado</label>
                  
                <select
                  id="estado"
                  name="estado"
                  className='select'
                  value={estado.uf}
                  onChange={handleChange}
                >
                  <option value="">Selecione</option>
                  {
                    estados.map((estado) => (
                      <option key={estado.uf} value={estado.uf}>{estado.name}</option>
                    ))
                  }
              </select>
            </div>
              <div>
                <label htmlFor="email">Cidade</label>
                <input
                  type="text"
                  id="cidade"
                  value={cidade}
                  onChange={(e) => setCidade(e.target.value)}
                  required
                />
              </div>
            </>
          )
        } 
     
        
      </form>
      <div className='container-botoes' style={{marginTop: '10px'}}>
          <Button id='btlg' onClick={handleClick} variant='contained' sx={{width: '300px'}} color={podeSalvar ? 'success' : 'secondary'} > {podeSalvar ? 'Cadastrar' : 'Completar'} {
            
            podeSalvar ? <PersonAddAltIcon></PersonAddAltIcon> :  <SetaDireita></SetaDireita>
            }
            
            </Button> 
        </div>
    </div>
  );
}

export default CreateAccount;
