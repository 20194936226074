 
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import './Container.css'
import PerfilDialog from '../components/perfilDialog/PerfilDialog';
import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import CarrinhoDialog from '../components/carrinho/CarrinhoDialog';
import HistoricoDialog from '../components/historicoPedidos/HistoricoDialog'
import ListAltIcon from '@mui/icons-material/ListAlt';
function Container() {
    const estiloIcone = {color: 'white', fontSize: '35px', marginLeft: '10px', marginRight: '10px', cursor: 'pointer', transition: '0.3s ease all'}
    const navigate = useNavigate()
    const [openPerfil, setOpenPerfil] = React.useState(false)
    const [openCarrinho, setOpenCarrinho] = React.useState(false)
    const [openHistorico, setOpenHistorico] = React.useState(false)

    function chamarNotificacaoInfo(mensagem) {
        toast.info(mensagem)
      }
      function chamarNotificacaoSucesso(mensagem) {
        toast.success(mensagem)
      }
      function chamarNotificacaoErro(mensagem) {
        toast.error(mensagem)
      }

    return (
        <>
            <nav>
        <div class="col-12 divlogo col-md-3">
            <img onClick={() =>  navigate('/')} style={{cursor: "pointer"}} id="logo" alt='logo' src="logo.png"/>
        </div>
        <div class="col-12 col-md-6">
            <div class="nav-central col-12">
                
                <div class="botoes-infos">
                    <div onClick={() => {
                        navigate('/orders') 
                    }}>
                        Eletrônicos
                    </div>
                    <div onClick={() => {
                        navigate('/orders') 
                    }}>
                        Social
                    </div>
                    <div onClick={() => {
                        navigate('/orders') 
                    }}>
                        I3D
                    </div>
                    <div onClick={() => {
                        navigate('/orders') 
                    }}>
                        Influencers
                    </div>
                    <div onClick={() => {
                        navigate('/orders') 
                    }}>
                        Contato
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-3">
            <div class="nav-user-info">
                
                
                {
                    localStorage.getItem('token') ? (
                        <>
                        <div>
                            <ListAltIcon onClick = {() => {
                                setOpenHistorico(true)
                            }} className='hovericone' sx={estiloIcone}></ListAltIcon>
                        </div>
                        <div>
                            <ShoppingCartIcon onClick = {() => {
                                setOpenCarrinho(!openCarrinho)
                            }}
                             className='hovericone' sx={estiloIcone}></ShoppingCartIcon>
                        </div>
                        <div>
                            <PersonIcon onClick = {() => {
                                setOpenPerfil(!openPerfil)
                            }} className='hovericone' sx={estiloIcone}></PersonIcon>
                        </div>
                        <div>
                            <LogoutIcon onClick={() => {
                                localStorage.clear()
                                window.location.reload()
                            }} className='hovericone' sx={estiloIcone}></LogoutIcon>
                        </div> 
                        </>
                    ) : 
                    <>  
                    <div onClick={() => {
                        navigate('/acesso')
                    }}>
                     <LoginIcon className='hovericone' sx={estiloIcone}></LoginIcon>
                    </div>
                      
                    </>
                }
                
                
            </div>
            <HistoricoDialog chamarNotificacaoErro={chamarNotificacaoErro} chamarNotificacaoInfo={chamarNotificacaoInfo} chamarNotificacaoSucesso={chamarNotificacaoSucesso} setAbrirModal={setOpenHistorico} abrirModal={openHistorico}></HistoricoDialog>
            <CarrinhoDialog chamarNotificacaoErro={chamarNotificacaoErro} chamarNotificacaoInfo={chamarNotificacaoInfo} chamarNotificacaoSucesso={chamarNotificacaoSucesso} setAbrirModal={setOpenCarrinho} abrirModal={openCarrinho}></CarrinhoDialog>
            <PerfilDialog chamarNotificacaoErro={chamarNotificacaoErro} chamarNotificacaoInfo={chamarNotificacaoInfo} chamarNotificacaoSucesso={chamarNotificacaoSucesso} setAbrirModal={setOpenPerfil} abrirModal={openPerfil}></PerfilDialog>
        </div>
       
        
        
      </nav>
      <div id="faixa">  
        <img src='plate.png' alt='plate' className='plate' ></img>
        <span className='letreiro2'> Wi-fi - Bluetooth - Acelerômetro - Temperatura</span>
        <span className='letreiro3'> Giroscópio - Umidade - Buzzer - USB-C</span>
        <span className='vejaMais1'><div><button className='routeButton' onClick={() => navigate('/sd0-more')}>Veja Mais</button></div></span>
        <div className='purple'>
          <span className='letreiro1'>SD-ZERO</span>
        </div>
      </div>

      <div className='figContainer'>
        <div>.</div>
        <div>.</div>
      </div>

      <ToastContainer containerId={'toastContainerjs'} position='top-center' />
        </>
    )
}

export default Container